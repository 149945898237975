@media (max-width: 426px) {
  .container-contact-page {
    transform: translateY(-30vh) !important;
  
    .text-zone {
      font-size: 12px;
      margin-left: 51vw !important;
      width: 46% !important;

      .title {
        margin-left: -50vw;
      }
    }
  }

  .Interviewing {
    margin-top: -105vh !important;
    opacity: 0;
  }

  .contact-form {
    margin-left: -49vw;
    margin-top: -48vh !important;
  }
}

.container-contact-page {
  margin-top: -20vh;

  .text-zone {
    margin-bottom: 30vh;
    width: 50%;
    margin-left: 2vw;
    font-size: 25px;
    line-height: 40px;
    
    color: #fff;
    
  
    .title {
      font-size: 80px;
      color: #c770f0;
      padding-bottom: 40px;
     
      }
    }
  
}

.Interviewing {
  margin-top: -70vh;
  margin-right: -105vw;
  width: 70%;
  height: auto;
}


.contact-form {
    width: 100%;
    max-height: 20%;
    margin-top: 20px;
  
    ul {
      padding: 0;
      margin: 0;
  
      li {
        padding: 0;
        margin: 0;
        list-style: none;
        margin-bottom: 10px;
        opacity: 0;
        overflow: hidden;
        display: block;
        clear: both;
        position: relative;
        animation: fadeInUp 2s 2s;
        animation-fill-mode: forwards;
      }
  
      li.half {
        width: 49%;
        margin-left: 2%;
        float: left;
        clear: none;
  
        &:first-child {
          margin-left: 0;
        }
      }
    }
  
    input[type='text'],
    input[type='email'] {
      width: 100%;
      border: 0;
      background: #115173;
      height: 50px;
      font-size: 16px;
      color: #fff;
      padding: 0 20px;
      box-sizing: border-box;
    }
  
    textarea {
      width: 100%;
      border: 0;
      background: #115173;
      height: 50px;
      font-size: 16px;
      color: #fff;
      padding: 20px;
      box-sizing: border-box;
      min-height: 150px;
    }
  
    .flat-button {
      color: #ffd700;
      font-size: 11px;
      letter-spacing: 3px;
      text-decoration: none;
      padding: 8px 10px;
      border: 1px solid #ffd700;
      float: left;
      border-radius: 4px;
      background: 0 0;
      text-transform: uppercase;
      float: right;
      text-align: center;
      margin-right: 10px;
      max-width: 100%;
      width: 98%;
    }
  }
  
  .map-wrap {
    background: rgba(8, 253, 216, 0.1);
    width: auto;
    height: auto
  
  }
  
  .leaflet-container {
    position: relative;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: fadeIn 1s 1.2s;
    animation-fill-mode: forwards;
  }
  
  .info-map {
    position: fixed;
    background: #000;
    top: 50px;
    right: 70%;
    z-index: 999999;
    width: 267px;
    padding: 20px;
    color: #fff;
    font-family: 'Helvetica';
    font-size: 17px;
    font-weight: 300;
    opacity: 0;
    animation: fadeIn 1s 1.5s;
    animation-fill-mode: forwards;
  
    span {
      font-size: 16px;
  
      span {
        color: #ffd700;
      }
    }
  }