@media (max-width: 426px) {
  .resume-container {
    margin-bottom: -100px;
  }
}

.resume-container {
  margin-top: -15vh;
  padding-bottom: 10vh;
  z-index: -9999
}

.large-flat-button {
  color: #ffd700;
  font-size: 13px; // Increase the font size for a larger button
  font-weight: 400;
  letter-spacing: 4px;
  text-decoration: none;
  padding: 10px 18px; // Adjust padding for a larger button
  border: 1px solid #ffd700;
  border-radius: 4px;
  background: 0 0;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  margin-left: -27vw;
  margin-top: -5vh !important;
  z-index: 9999;
  width: 35vw;
  
  
  text-transform: uppercase;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 10%;
    height: 100%;

    border-radius: 10rem;
    z-index: -2;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #ffd700;
    transition: all .3s;


    z-index: -1;
  }
  &:hover {
    color: black;
    &:before {
      width: 100%;
    }
  }
}

.home-page::-webkit-scrollbar {
width: 0.5em;
}