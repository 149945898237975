@media (max-width: 426px) {
  .container-about-page {
    margin-top: 100px !important;
    transform: translateY(-160px) !important;
  }

  .text-zone {

    font-size: 13px !important;
    .title {
      font-size: 90px !important;
      max-width: 1000px !important;
      width: 410px !important;
    }
    .paragraphs {
      margin-top: 20px !important;
      width: 80%;
    }
  }

  .headshot {
    width: 50% !important;
    height: auto !important;
    margin-left: -60px !important;
    margin-bottom: -70px !important;
  }

  .stage-cube-cont {
    max-width: 55% !important;
    max-height: auto !important;
    margin-right: -10px !important;
    margin-top: -850px !important;
  }
}

.container-about-page {
  background-color: #022c43; /* Change background color */
  margin-top: 55vh;
}

.paragraphs {
  margin-top: 100px;
}



.text-zone {
  margin-bottom: 30vh;
  width: 50%;
  margin-left: 2vw;
  font-size: 25px;
  
  color: #fff;
  

  .title {
    font-size: 80px;
    color: #c770f0;
   
    }
  }


.headshot {
    width: 400px; /* Adjust this value based on your design */
    max-width: 400px; /* Prevent the image from becoming too large */
    padding-top: -100vh;
    margin-top: -100vh;
    position: relative;
    overflow: hidden;
    margin-right: -60vw;
  }
  
  
  
  
  .stage-cube-cont {
    width: 100%;
    height: 100%;
    max-width: 40%;
    padding-top: 22vw;
    position: absolute;
    right: 0vw;
    overflow: hidden;
    margin-top: -140vh;

  }
  
  .cubespinner {
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    transform-style: preserve-3d;
    transform-origin: 100px 100px 0;
    margin-left: calc(50% - 100px);
    
  
    div {
      position: absolute;
      width: 200px;
      height: 200px;
      border: 1px solid #ccc;
      background: rgba(255, 255, 255, 0.4);
      text-align: center;
      font-size: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 20px 0px lightyellow;
    }
  
    .face1 {
      transform: translateZ(100px);
      color: #dd0031;
    }
    .face2 {
      transform: rotateY(90deg) translateZ(100px);
      color: #f06529;
    }
    .face3 {
      transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
      color: #28a4d9;
    }
    .face4 {
      transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
      color: #5ed4f4;
    }
    .face5 {
      transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
      color: #efd81d;
    }
    .face6 {
      transform: rotateX(-90deg) translateZ(100px);
      color: #ec4d28;
    }
  }
  
  @keyframes spincube {
    from,
    to {
      transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    }
    16% {
      transform: rotateY(-90deg) rotateZ(90deg);
    }
    33% {
      transform: rotateY(-90deg) rotateX(90deg);
    }
    50% {
      transform: rotateY(-180deg) rotateZ(90deg);
    }
    66% {
      transform: rotateY(-270deg) rotateX(90deg);
    }
    83% {
      transform: rotateX(90deg);
    }
  }
  
  