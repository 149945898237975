/* typewriter.css */


@media (max-width: 767px) {
    .Typewriter__wrapper {
      font-size: 1.4em ;
      font-weight: 500 ;
      position: absolute ;
      margin-top: 1000px !important;
      z-index: 9999;
    }
    .Typewriter__cursor {
      display: none ;
    }
  }

  
.Typewriter__wrapper {
    font-size: 2.2em ;
    font-weight: 600 ;
    animation: fadeIn 10s;
  }
  
  .Typewriter__cursor {
    font-size: 2.4em ;
    animation: fadeIn 10s;
  }
  